import React, {Component} from 'react';
import {AnnouncementBarConstants} from '../../adapters/helpers/Constants'
import {isLater} from '../../adapters/helpers/functions'
import Block from '../../adapters/helpers/Block';
import { AnalyticsConstants } from '../../adapters/helpers/ConstantsGA';
import Icon from '../Icon/Icon';
import {Label} from '../Label/Label'

class AnnouncementBar extends Component {
    
    constructor(props) {
        super(props);
        this.block = new Block(props);
        this.announcementBar = React.createRef();
        this.disableDays = this.block.getFieldValue(AnnouncementBarConstants.disableDays);
        this.closeAriaLabel = Label({label: this.block.getFieldValue(AnnouncementBarConstants.closeAriaLabel)});
        this.callToActionLabel = this.valueOf(AnnouncementBarConstants.callToActionLabel);
    }
    
    valueOf(name) {
        return this.block.getFieldValue(name);
    }
    
    componentDidMount() {
        //Display announcementBar is there isn't localStorage or Storage date < Now
        let layoutCommon = document.querySelector('.layout');
        const storage = localStorage.getItem(AnnouncementBarConstants.AnnouncementBarDisableDate);
        if (!storage || (storage && isLater(storage))) {
            let  isProduct = document.querySelector('.ob-product-sub-nav.is-sticky');
            if(isProduct){
                layoutCommon.classList.add('addPaddingBreadcrumb')
            }
        } else {
            const nodeBar = document.querySelector('.ob-announcement-bar');
            let  firstBlockWithBreadcrumb = document.querySelector('.ob-product-sub-nav.is-sticky.addPaddingBreadcrumb');
            if(firstBlockWithBreadcrumb){
                firstBlockWithBreadcrumb.classList.remove('addPaddingBreadcrumb')
            }
            if (nodeBar.parentNode) {
                nodeBar.parentNode.removeChild(nodeBar);
                const layout = document.querySelector('.layout:not(.ob-spotlight-layout)');
                layout.classList.add('layout-announcement-bar-hidden')
            }
        }
    }
    
    reduceLayoutPadding = () => {
        const utilityHeader = document.querySelector('.ob-utilityBar');
        let utilityHeaderHeight = 0;
        if (utilityHeader) {
            utilityHeaderHeight = utilityHeader.clientHeight;
        }
        const mainMenu = document.querySelector('.main-menu');
        let mainMenuHeight = 0;
        if (mainMenu) {
            mainMenuHeight = mainMenu.clientHeight;
        }
        const height = utilityHeaderHeight + mainMenuHeight;
        const layout = document.querySelector('.layout:not(.ob-spotlight-layout)');
        if (layout) {
            layout.style.paddingTop = `${height}px`;
        }
        
        const productSubNav = document.querySelector('.ob-product-sub-nav.is-sticky');
        if (productSubNav) {
            productSubNav.style.top = `${height}px`;
        }
                
        const header = document.querySelector('.zone-header');
        const nodeBar = document.querySelector('.ob-announcement-bar');
        let layoutClass = document.querySelector('.layout.addPaddingBreadcrumb');
        if(layoutClass){
            layoutClass.classList.remove('addPaddingBreadcrumb')
        }
        let  firstBlock = document.querySelector('.ob-product-sub-nav.is-sticky');
        const breadcrumbs = document.querySelector('.breadcrumb-main.visible-false');
        if(breadcrumbs){
            breadcrumbs.style.top = `${header.offsetHeight-nodeBar.offsetHeight}px`;
            if (firstBlock) {
                firstBlock.style.top = `${header.offsetHeight}px`;
            }
        }
        else{
            if (firstBlock) {
                firstBlock.style.top = 'auto';
            }
        }
    }
    onToggle = (evt) => {
        const nodeBar = document.querySelector('.ob-announcement-bar');
        let mainEle = document.getElementById('main');
        const layout = document.querySelector('.layout:not(.ob-spotlight-layout)');
        nodeBar.style.display = 'none'
        if(mainEle) {
            mainEle.classList.add('ob-main-layout-align-padding');
            layout.classList.add('layout-announcement-bar-hidden')
        }
        this.handleClose();
    }
    
    handleClose() {
        if (this.disableDays) {
            var today = new Date();
            var disableDays = new Date();
            disableDays.setDate(today.getDate() + this.disableDays);
            localStorage.setItem(AnnouncementBarConstants.AnnouncementBarDisableDate, disableDays);
        }
        const layout = document.querySelector('.zone.announcement');
        if (layout ) {
            layout.style.minHeight = '0px';
        }
    }
    
    render() {
        
        return (
            <div className='ob-announcement-bar'>
                <div className='ob-announcement-bar-inner'>
                <div className='description'>
                    {this.valueOf(AnnouncementBarConstants.description)}
                </div>

                { this.callToActionLabel &&
                    <a aria-label={`${this.callToActionLabel}${AnnouncementBarConstants.ctaName}`} className='event_button_click ob-announcement-bar-cta'
                        href={this.valueOf(AnnouncementBarConstants.callToActionLink)}
                        data-action-detail={AnalyticsConstants.learnMoreText}
                        target={this.valueOf(AnnouncementBarConstants.target)}
                    >
                        {this.callToActionLabel}
                    </a>
                }
                
                <button className='event_close_window ob-announcement-bar-close-btn'
                        onClick={this.onToggle} aria-label={this.closeAriaLabel}>
                    <Icon name={AnnouncementBarConstants.close} size='1.2' color={'#FFFFFF'} />
                </button>                            
                </div>
            </div>
        )
    }
}

export default AnnouncementBar
