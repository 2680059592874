import React, { useState, useEffect } from 'react';
import { inputs } from './inputs';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import OtpInput from 'react-otp-input';
import Eyebrow from '../../../../Eyebrow/Eyebrow';
import { SubmitButton, CheckboxInput } from '../../../components';
import { redirectTo } from '../../../helpers/redirect.helpers';
import { getProductByTypeCode, getProductBySeries } from '../../../../../adapters/model/service/warrantyExtension/warrantyExtension.helpers';
import Image from '../../../../Image/Image';
import ResponsiveImage from '../../../../ResponsiveImage/ResponsiveImage';
import { WarrantyExtensionConstants } from '../../../../../adapters/helpers/Constants';
import Modal from 'react-modal';
import Button from '../../../../Button/Button';
import List from '../../../components/List';
import { getIsCreatingAccountFromLocalStorage } from '../../../helpers/localstorage.helpers';
import { customJsonstringify } from "../../../../../adapters/helpers/Utils";
const RegisterTypeCodeForm = ({ formHeading, labels, tipImage, validTypeCodeImage, 
    successRedirectPath, lstInternSubGroup, lstSeries }) => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isCombo, setCombo] = useState(false);
    const [typeCode, setTypecode] = useState(0);
    const [isValidating, setValidatingTypecode] = useState(false);
    const [isValidated, setValidated] = useState(false);
    const [inValidTypeCode, setInValidTypecode] = useState(false);
    const [serverError, setServerError] = useState(false);
    const [responseMessage, setResponseMessage] = useState('');

    //combo pack
    const [comboTypecode, setComboTypecode] = useState(0);
    const [isValidatingCombo, setValidatingTypecodeCombo] = useState(false);
    const [isValidatedCombo, setValidatedCombo] = useState(false);
    const [inValidTypecodeCombo, setInValidTypecodeCombo] = useState(false);
    const [serverErrorCombo, setServerErrorCombo] = useState(false);
    const [responseMessageCombo, setResponseMessageCombo] = useState('');

    const [isModalOpen, setModelOpen] = useState(false);
    const [isComboModel, setIsComboModel] = useState(false);
    const [choiceIndex, setChoiceIndex] = useState(0);
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const [typeCodeList, setTypeCodeList] = useState([]);
    const [rawTypeCodes, setRawTypeCodes] = useState([]);
    const [isTypeCodeLoading, setTypecodeListLoading] = useState(false);
    const [isCreatingAccount, setIsCreatingAccount] = useState(false);
    const TYPECODE_LENGTH = 4;
    const closeModalTimeoutMS = 250;

    const customStyles = {
        overlay: {
            boxShadow           : 'rgba(0, 0, 0, 0.5) 0 2px 4px',
            background          : 'rgba(0, 0, 0, 0.5)',
            display             : 'flex',
            justifyContent      : 'center',
            alignItems          : 'center'
        },
        content: {
            position            : 'static',
            display             : 'flex'
        }
    };

    function comp(code, name) {
        return (
            <div className="typecode">
                <div className="code">{code}</div>
                <div className="name">{name}</div>
            </div>
        )
    }
    
    const fetchProducts = async (lstInternSubGroup, lstSeries) => {
        setTypecodeListLoading(true);
        let typecodes = await getProductBySeries(lstInternSubGroup, lstSeries);
        if (typecodes) {
            setRawTypeCodes([...typecodes]);
            typecodes = typecodes.map(typeCode => {
                return comp(typeCode.sArticleGroup, typeCode.sMarketingNameEn);
            });
            setTypeCodeList(typecodes)
        } else {
            setRawTypeCodes([]);
            setTypeCodeList([]);
        }
        setTypecodeListLoading(false);
    };

    useEffect(() => {
        setTypecode(0);
        setComboTypecode(0);
        clearTypeCodeVerify();
        clearComboTypeCodeVerify();
        fetchProducts(lstInternSubGroup, lstSeries);
        setIsCreatingAccount(getIsCreatingAccountFromLocalStorage() == 'true');
    }, []);
    
    const clearTypeCodeVerify = () => {
        setValidated(false);
        setInValidTypecode(false);
        setServerError(false);
        localStorage.removeItem(WarrantyExtensionConstants.gasPrimaryProductDetailKey);
    }

    const isValidInternGroup =  (jsonResponse) => {
        return Array.isArray(jsonResponse) && jsonResponse.length > 0 && jsonResponse[0]?.sInternGroup === WarrantyExtensionConstants.internGroupValue;
    }

    const verifyTypeCode = async (typecode) => {
        clearTypeCodeVerify();
        setValidatingTypecode(true);
        const response = await getProductByTypeCode(typecode);
        const jsonResponse = await response?.json();
        const validInternGroup = isValidInternGroup(jsonResponse);
        if (response?.status === 200 && validInternGroup) {
            setValidated(true);
            const primaryProductDetail = {
                name: jsonResponse[0].sMarketingNameEn,
                image: jsonResponse[0].sImg,
                typeCode: typecode,
                marketingName: jsonResponse[0].sMarketingNameEn
            }
            localStorage.setItem(WarrantyExtensionConstants.gasPrimaryProductDetailKey,  customJsonstringify(primaryProductDetail))
            setResponseMessage(jsonResponse[0].sMarketingNameEn)
        } else if (response?.status === 400 || !validInternGroup) {
            setInValidTypecode(true);
            setResponseMessage(response.message)
        } else {
            setServerError(true);
            setResponseMessage('Server Error')
        }
        setValidatingTypecode(false);
    }

    const clearComboTypeCodeVerify = () => {
        setValidatedCombo(false);
        setInValidTypecodeCombo(false);
        setServerErrorCombo(false);
        localStorage.removeItem(WarrantyExtensionConstants.gasSecondaryProductDetailKey);
    }

    const verifyComboTypeCode = async (comboTypecode) => {
        clearComboTypeCodeVerify();
        setValidatingTypecodeCombo(true);
        const response = await getProductByTypeCode(comboTypecode);
        const jsonResponse = response && await response.json();
        const validInternGroup = isValidInternGroup(jsonResponse);
        if (response?.status === 200 && validInternGroup) {
            setValidatedCombo(true);
            const secondaryProductDetail = {
                name: jsonResponse[0].sMarketingNameEn,
                image: jsonResponse[0].sImg,
                typeCode: comboTypecode,
                marketingName: jsonResponse[0].sMarketingNameEn
            }
            localStorage.setItem(WarrantyExtensionConstants.gasSecondaryProductDetailKey,  customJsonstringify(secondaryProductDetail))
            setResponseMessageCombo(jsonResponse[0].sMarketingNameEn)
        } else if (response?.status === 400 || !validInternGroup) {
            setInValidTypecodeCombo(true);
            setResponseMessageCombo(response.message)
        } else {
            setServerErrorCombo(true);
            setResponseMessageCombo('Server Error')
        }
        setValidatingTypecodeCombo(false);
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsSubmitting(true);
        redirectTo(successRedirectPath)
    };

    const handleTypecodeChange = typecode => {
        setTypecode(typecode);
        clearTypeCodeVerify();
        if (typecode?.toString().length === TYPECODE_LENGTH) {
            verifyTypeCode(typecode);
        }
    };

    const handleComboTypecodeChange = comboTypecode => {
        setComboTypecode(comboTypecode);
        clearComboTypeCodeVerify();
        if (comboTypecode?.toString().length === TYPECODE_LENGTH) {
            verifyComboTypeCode(comboTypecode);
        }
    };

    const selectComboTypeCode = () => {
        clearComboTypeCodeVerify();
        setComboTypecode('');
        setCombo(!isCombo);
    }

    const isValidTypeCodeIndex = (selectedIndex) => {
        return selectedIndex > -1;
    }

    const openTypeCodeListModel = (e, isComboModel = false) => {
        e.preventDefault();
        setChoiceIndex(selectedIndex);
        setIsComboModel(isComboModel);
        setModelOpen(true);
    }
    const closeModal = (e) => {
        e.preventDefault();
        setModelOpen(false);
    }

    const updateTypecode = (e) => {
        e.preventDefault();
        setSelectedIndex(choiceIndex);
        if(isValidTypeCodeIndex(choiceIndex)) {
            const typeCode = rawTypeCodes[choiceIndex]?.sArticleGroup;
            if(isComboModel) {
                handleComboTypecodeChange(typeCode);
            } else {
                handleTypecodeChange(typeCode);
            }
        }
        closeModal(e);
    }

    const isEmptyTypeCodeList = () => !isTypeCodeLoading && typeCodeList.length === 0;

    const displayInvalidTypeCodeError = !isValidated && inValidTypeCode;
    const displayInvalidComboTypeCodeError = !isValidatedCombo && inValidTypecodeCombo;
    return (
        <div className='ob-register-typecode'>
            <div className='ob-form__layout ob-register-typecode__wrapper'>
                <form className='ob-form__form' name={inputs.formName} onSubmit={handleSubmit} data-autosubmit="false" autocomplete="off">
                    <h1 className='ob-form__title'>{formHeading}</h1>
                    <Eyebrow className='ob-register-typecode__step-title'>{isCreatingAccount ? labels?.description : labels?.comesBackDescription}</Eyebrow>
                    <h2 className='ob-register-typecode__sub-title'>{labels?.subTitle}</h2>
                    <div className='ob-register-typecode__input-entry-wrapper'>
                        <div className='ob-register-typecode__input-box-wrapper'>
                            <div className='ob-register-typecode__type-code-input-wrapper'>
                                <div className='ob-form__wrapper ob-register__row ob-register-typecode__digit-group'>
                                    <OtpInput
                                        value={typeCode}
                                        onChange={handleTypecodeChange}
                                        numInputs={TYPECODE_LENGTH}
                                        isInputNum={true}
                                        separator={<span className='ob-register-typecode__seperator'></span>}
                                        hasErrored={displayInvalidTypeCodeError}
                                        errorStyle={'ob-register-typecode__txtbox-error-style'}
                                    />
                                    {isValidating && <span className={'ob-register-typecode__spinner'}></span>}
                                    {isValidated && <Image image={validTypeCodeImage} />}
                                </div>
                                {isValidated && 
                                    <>
                                        <Eyebrow className='ob-register-typecode__product-list-title'>{labels?.brushListTitle}</Eyebrow>
                                        <Eyebrow className={'ob-register-typecode__valid-typecode'}>{responseMessage}</Eyebrow>
                                    </>
                                    }
                                {displayInvalidTypeCodeError && <Eyebrow className={'ob-register-typecode__invalid-typecode'}>{labels?.invalidTypeCodeErrorText}</Eyebrow>}
                                {serverError && <Eyebrow className={'ob-register-typecode__invalid-typecode'}>{responseMessage}</Eyebrow>}
                                {!isValidated && <button aria-label={labels?.selectFromListText} className='ob-register-typecode__btn-select-from-list' onClick={openTypeCodeListModel}>{labels?.selectFromListText}</button>}
                                {labels?.comboProductPackOptionText && <>
                                    <CheckboxInput
                                        label={`${labels?.comboProductPackOptionText}`}
                                        name={'show-combo'}
                                        checked={isCombo}
                                        onChange={selectComboTypeCode}
                                        className={'ob-register-typecode__combo-pack'}
                                    />
                                    {labels?.comboPackOptionHelpText &&
                                        <div className='ob-register-typecode__info-text-bottom'>
                                            <p dangerouslySetInnerHTML={{ __html: labels?.comboPackOptionHelpText }}></p>
                                        </div>
                                    }
                                </>}
                            </div>
                            {isCombo && <div className='ob-register-typecode__type-code-input-wrapper'>
                                        <div className='ob-form__wrapper ob-register__row ob-register-typecode__digit-group'>
                                            <OtpInput
                                                value={comboTypecode}
                                                onChange={handleComboTypecodeChange}
                                                numInputs={TYPECODE_LENGTH}
                                                isInputNum={true}
                                                separator={<span className='ob-register-typecode__seperator'></span>}
                                                hasErrored={displayInvalidComboTypeCodeError}
                                                errorStyle={'ob-register-typecode__txtbox-error-style'}
                                            />
                                            {isValidatingCombo && <span className={'ob-register-typecode__spinner'}></span>}
                                            {isValidatedCombo && <Image image={validTypeCodeImage} />}
                                        </div>
                                        {isValidatedCombo &&
                                            <>
                                                <Eyebrow className='ob-register-typecode__product-list-title'>{labels?.brushListTitle}</Eyebrow>
                                                <Eyebrow className={'ob-register-typecode__valid-typecode'}>{responseMessageCombo}</Eyebrow>
                                            </>}
                                        {displayInvalidComboTypeCodeError && <Eyebrow className={'ob-register-typecode__invalid-typecode'}>{labels?.invalidTypeCodeErrorText}</Eyebrow>}
                                        {serverErrorCombo && <Eyebrow className={'ob-register-typecode__invalid-typecode'}>{responseMessageCombo}</Eyebrow>}
                                        {!isValidatedCombo && <button aria-label={labels?.selectFromListText} className='ob-register-typecode__btn-select-from-list' onClick={(e)=> openTypeCodeListModel(e, true)}>{labels?.selectFromListText}</button>}
                                    </div>
                            }
                            <SubmitButton
                                isSubmitting={isSubmitting}
                                disabled={(isCombo ? ((!isValidated || !isValidatedCombo) || isSubmitting) : (!isValidated || isSubmitting))}
                                buttonText={labels?.registerTypeCodeButtonText}
                            />
                            {(displayInvalidTypeCodeError || (isCombo && displayInvalidComboTypeCodeError)) && <Eyebrow className='ob-register-typecode__invalid-typecode-helptext'>{labels?.invalidTypeCodeHelpText}</Eyebrow>}
                        </div>
                        <div className='ob-register-typecode__type-code-image'>
                            <ResponsiveImage document={tipImage} />
                            <Eyebrow className='ob-register-typecode__tipimage-text'>{labels?.proTipText}</Eyebrow>
                        </div>
                    </div>
                </form>
            </div>
            <Modal
                isOpen={isModalOpen}
                onRequestClose={closeModal}
                closeTimeoutMS={closeModalTimeoutMS}
                style={customStyles}
                
                htmlOpenClassName={'ob-register-typecode__modal-overlay'}
            >
                <div className={'ob-register-typecode__modal-container'}>
                    <div className='ob-register-typecode__select-typecode'>
                        <h2 className='ob-register-typecode__select-typecode-title'>{labels?.selectTypecodeTitle}</h2>
                        {!isEmptyTypeCodeList() && <List items={typeCodeList} 
                            onChange={setChoiceIndex}
                            selected={[choiceIndex]}
                            keyboardEvents={true}
                         />}
                         {isEmptyTypeCodeList() && <div className='ob-register-typecode__no-typecode-error'><p>{labels?.noTypeCodesError}</p></div>}
                         {isTypeCodeLoading && <div className='ob-register-typecode__list-spinner'></div>}
                    </div>
                    <div className={'ob-register-typecode__modal-button-container'}>
                        {!isEmptyTypeCodeList() && <SubmitButton
                            buttonText={labels?.typecodeListPopupSelectLabel}
                            onClick={updateTypecode}
                            disabled={!isValidTypeCodeIndex(choiceIndex)}
                        />}
                        <Button
                            textTheme={true}
                            className={'ob-profile__modal-cancel-btn'}
                            onClick={closeModal}
                        >
                            {labels?.typecodeListPopupCancelLabel}
                        </Button>
                    </div>
                </div>
            </Modal>
        </div>
    )
};

RegisterTypeCodeForm.propTypes = {
    formHeading: PropTypes.string.isRequired,
    labels: PropTypes.any
};

export default observer(RegisterTypeCodeForm);
