/* eslint-disable react/prop-types */
import React from 'react';


const HitComponent = ({ hit }) => {
  const { url, description, _highlightResult, image, title } = hit;

  let highlightResult = _highlightResult;
  return (
    <>
      <div className='ob-search-block__search-result'>
        <div className='ob-search-block__search-result-content'>
          <div className='ob-search-block__search-result-header'>
            <a href={url} className='event_internal_link' data-action-detail={hit?.title}>
              <span className='ob-search-block__search-result-chevron icon-chevronright' />
              <span
                className='ob-search-block__search-result-title'
                dangerouslySetInnerHTML={{
                  __html: highlightResult?.title?.value,
                }}
              />
            </a>
          </div>
          {description && (
            <div
              className='ob-search-block__search-result-body'
              dangerouslySetInnerHTML={{
                __html: highlightResult?.description?.value,
              }}
            />
          )}
        </div>
        {image && (
          <div className='ob-search-block__search-result-image-wrapper'>
            <a href={url}>
              <img
                src={image}
                alt={title}
                className='ob-search-block__search-result-image'
              />
            </a>
          </div>
        )}
      </div>
    </>
  );
};

export default React.memo(HitComponent);
