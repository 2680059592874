import React, { useEffect,useCallback, useRef,useState } from 'react';
import PropTypes from 'prop-types';
import { getReviewAndRatingId} from '../../adapters/helpers/Utils';
import { useIsomorphicLayoutEffect } from '../../adapters/helpers/Hooks';
import { handleFindElement } from '../../adapters/helpers/Utils';
import { UserQuoteConstants } from '../../adapters/helpers/Constants';
import ProductSEO from './ProductSchema';


function CustomerReview(props) {
    let [rating, setRating] = useState();
    let [review, setTotalReview] = useState();
    const ratingRefernce = useRef()   
    const reviewAndRatingId = getReviewAndRatingId(props?.product?.productOverview);
    useEffect(() => {
        handleRatingandReviews();
    }, []);
    const handleRatingandReviews=()=>{
        const requestOptions = {
        method: 'GET',
        };
    let RnRURL = process.env.BAZAAR_VOICE_RNR?.replace('{reviewAndRatingId}', reviewAndRatingId);
            
        fetch(RnRURL, requestOptions).then(async (response) => {
            if(response.ok) {
                const output= await response.json();
                return output;
            }
        }).then(async (output) => {
                setRating(output?.reviewSummary?.primaryRating?.average)
                setTotalReview(output?.reviewSummary?.numReviews)

        })
        .catch((error) => {
        console.error('Bazaar Voice RNR API: ', error);
        });
    }
    useEffect(() => {
        document.addEventListener('DOMContentLoaded', handleFindElement());
        return () => document.removeEventListener('DOMContentLoaded', handleFindElement());
        
    },[]); 
    useEffect(() => {
        const config = { attributes: false, characterData: false, childList: true, subtree: true, attributeOldValue: false, characterDataOldValue: false };
        const observer = new MutationObserver(mutations => {
            mutations.forEach(mutation => {
                const newNodes = mutation.addedNodes;               
                newNodes.forEach(node => {                    
                    if (node&& node.nodeType==1 && node.normalizedNodeName === UserQuoteConstants.containerElement && node.getElementsByClassName('bv_avgRating_component_container') && node?.getElementsByClassName('bv_avgRating_component_container')[0]) {                
                        setRating(node?.getElementsByClassName('bv_avgRating_component_container')[0]?.textContent);
                    }

                    if (node &&node.nodeType==1  && node.normalizedNodeName === UserQuoteConstants.containerElement && node.textContent !== UserQuoteConstants.reviewSection && node.textContent !== '' && node.getElementsByClassName('bv_numReviews_text')) {                    
                        setTotalReview(node?.getElementsByClassName('bv_numReviews_text')[0]?.textContent?.replace(/[^0-9]/g,''));
                    }        
              });
            });
        });
        observer.observe(ratingRefernce.current, config);
    }, []); 

    return (
        <>
            { props.isDisplay &&
                <div className='ob-customer-review' >
                   
                    {reviewAndRatingId &&
                        <div className='ob-customer-review-container' id='BVCustomerReviewContiner'>
                            <h2 className='ob-customer-review-title'>{props.title}</h2>

                            <div ref={ratingRefernce} data-bv-show={UserQuoteConstants.ratingSummary} data-bv-product-id={reviewAndRatingId}></div>

                            <div  data-bv-show={UserQuoteConstants.reviews} data-bv-product-id={reviewAndRatingId}></div>

                            <div data-bv-show={UserQuoteConstants.questions} data-bv-product-id={reviewAndRatingId}></div>
                        </div>
                    }
                </div>
            }
            <ProductSEO
                productDetails={props?.product?.productOverview?.fields}
                rating = {rating}
                review={review}              
            /> 
        </>
    )
}

CustomerReview.propTypes = {
    product: PropTypes.object,
    productName: PropTypes.string,
    title: PropTypes.string,
    isDisplay: PropTypes.bool,
}


export default CustomerReview
