import React, { useState,useLayoutEffect, useEffect } from 'react';
import PropTypes from 'prop-types'
import Helmet from 'next/head'
import { Constants } from '../../adapters/helpers/Constants';
import ImageFactory from '../../adapters/cloudinary/ImageFactory';
import { MetaConstants } from '../../adapters/helpers/Constants';
import { getCanonicalURL } from '../../adapters/helpers/Utils';

const ProductPageSEO = ({ productDetails,rating,review}) => {
    let aggre=''
    if(rating !== null || review !== null){
      aggre=`
        ,"aggregateRating": {
            "@type": "AggregateRating",
            "itemReviewed":{
                "@type":"IndividualProduct",
                "name":"${productDetails?.name}"
            },
            "bestRating": "5",
            "worstRating": "1",
            "ratingValue": "${rating}",
            "reviewCount": "${review}"
            }`
        
    }
 
     return  (        
        <Helmet>           
            <script
                type={Constants.typeLD}
                dangerouslySetInnerHTML={{
                    __html: `{
                        "@context": "${Constants.context}",
                        "@type": "${Constants.product}",
                        "brand": {
                            "@type": "Brand",
                            "name": "${Constants.brand}"
                        },
                        "name": "${productDetails?.name}",
                        "description": "${productDetails?.shortDescription || ''}",
                        "image": "${ImageFactory.buildContentfullImageUrl(productDetails?.mainAsset?.fields?.asset?.fields?.file?.url) || ''}",
                        "sku": "${productDetails?.productVariants  && productDetails?.productVariants[0]?.fields?.sku}",
                        "gtin8": "${productDetails?.productVariants && productDetails?.productVariants[0]?.fields?.sku}",
                        "id":"${getCanonicalURL(productDetails?.slug?.fields?.slug, MetaConstants.https)}"
                        ${aggre}                   
                        }`,
                }}
            />
        </Helmet>
    ) 
}

ProductPageSEO.propTypes = {
    productDetails: PropTypes.object.isRequired,
}

export default ProductPageSEO
