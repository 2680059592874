import SearchService from '../../adapters/model/service/swiftype/search/SearchService';
import SearchBar from '../SearchBar/SearchBar';
import SearchToggleButton from '../SearchBlock/SearchToggleButton';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {SearchBlockConstants} from '../../adapters/helpers/Constants';
import Paging from '../Paging/Paging';
import FAQQA from './FAQQA';
import Heading from '../Heading/Heading';
import { customJsonstringify } from '../../adapters/helpers/Utils';
export default class FAQBlock extends Component {
    constructor(props) {
        super(props);

        this.document = props.document.fields;
        this.pageTitle = this.document?.pageTitle ? this.document?.pageTitle : '';
        this.searchPlaceholder = this.document?.searchActionTitle ? this.document?.searchActionTitle?.fields?.text : '';
        this.searchTitle = this.document?.searchTitle ? this.document?.searchTitle : '';
        this.resultsBySectionLabel = this.document?.resultsBySectionLabel ? this.document?.resultsBySectionLabel : '';
        this.noResultLabel = this.document?.noResultLabel ? this.document?.noResultLabel : '';
        this.resultLabel = this.document?.resultLabel ? this.document?.resultLabel : '';
        this.style = this.document?.style;
        this.popularResults = this.getPopularResults();
        this.popularResultsByTopic = this.getPopularResultsByTopic();
        this.popularTopics = this.getPopularTopics();
        this.popularTopicsText = this.document?.popularTagsLabel ? this.document?.popularTagsLabel : '';

        this.commonSearchesLabel = this.document?.commonSearchesLabel ? this.document?.commonSearchesLabel?.fields?.text : null;
        this.resultsTitle = '';

        this.TOTAL_ITEMS_PER_PAGE = 10;
        this.pageSize = this.document?.pageSize ? this.document?.pageSize : this.TOTAL_ITEMS_PER_PAGE;

        this.state = {
            query: '',
            isSearching: false,
            allFaqs: [],
            searchResults: [],
            pagedResults: [],
            searchCategories: [],
            currentCategoryIndex: 0,
            totalResults: 0,
            currentPage: 1,
            totalPages: 0,
            activeItem: -1,
            popularResultsByTopic: this.popularResultsByTopic,
            filteredPopularResults: this.popularResults,
        };
    }

    componentDidMount() {
        this.manageResultsForPopularTopics(0);
        this.handleHashChange();
        this.getAllFaqs();
        window.addEventListener('hashchange', this.handleHashChange.bind(this));
    }

    componentWillUnmount() {
        window.removeEventListener('hashchange', this.handleHashChange.bind(this));
    }

    async handleHashChange() {
        const query = this.getHashVariable(SearchBlockConstants.queryHashName);
        if (query && query.length > 2) {
            this.setState({query: query});
            this.setState({isSearching: true});
            this.setState({currentPage: 1});
            await this.doSearch(query);
        } else {
            this.manageError(query);
        }
    }

    handleSearch(keywords) {
        if (keywords && keywords.length > 2) {
            window.location.hash = SearchBlockConstants.queryHashName + SearchBlockConstants.queryHashEqual + keywords;
        } else {
            window.location.hash = '';
        }
    }

    async getAllFaqs() {
        let allFaqs = [];
        const searchResults = await SearchService.searchFaq('').catch((error) => {
            console.error(error);
        });
        for (let i = 0; i < searchResults.searchResults.length; i++) {
            let isPopular = false;
            const result = searchResults.searchResults[i];
            for (let j = 0; j < this.popularResults.length; j++) {
                const popularResult = this.popularResults[j].fields;
                if (popularResult.name === result.name) {
                    isPopular = true;
                }
            }
            if (!isPopular) {
                allFaqs.push({name: result.name, url: result.url})
            }
        }
        this.setState({allFaqs: allFaqs});
    }

    async doSearch(query) {
        const searchResult = await SearchService.searchFaq(query).catch(() => {
            this.manageError(query);
        });

        this.dataLayerProductPageEvent(query, true);

        this.manageResults(searchResult, query);
    }

    manageError() {
        this.setState({
            searchResults: [],
            pagedResults: [],
            searchCategories: [],
            currentCategoryIndex: 0,
            searchResultsByCategory: {},
            isSearching: false,
            query: '',
            totalCount: 0,
            currentPage: 1,
            totalPages: 0,
            activeItem: -1,
            filteredPopularResults: this.popularResults,
        });
    }

    manageResults(resultsJSON, query) {
        let results = [];
        let resultsByCategory = {};
        let categories = ['All'];
        let totalCount = 0;

        if (resultsJSON && resultsJSON.totalCount) {
            totalCount = resultsJSON.totalCount;
        }

        if (resultsJSON && resultsJSON.searchCategories) {
            categories = categories.concat(resultsJSON.searchCategories);
        }

        if (resultsJSON && resultsJSON.searchResults) {
            results = resultsJSON.searchResults;
        }

        if (resultsJSON && resultsJSON.searchResultsByCategory) {
            resultsByCategory = resultsJSON.searchResultsByCategory;
        }

        this.setState({
            searchResults: results,
            searchCategories: categories,
            currentCategoryIndex: 0,
            searchResultsByCategory: resultsByCategory,
            isSearching: false,
            query: query,
            totalCount: totalCount,
            totalPages: Math.ceil(totalCount / this.pageSize),
        });

        this.managePagedResults(results, this.state.currentPage);
    }

    managePagedResults(results, page) {
        if (results) {
            if (page < 1) {
                page = 1;
            }
            if (page > this.state.totalPages) {
                page = this.state.totalPages;
            }

            let pagedResults = results.slice((page - 1) * this.pageSize, page * this.pageSize);

            this.setState({
                currentPage: page,
                pagedResults: pagedResults,
            });
        }
    }

    manageResultsForCategory(categoryIndex) {
        if (this.state.searchCategories && this.state.searchCategories[categoryIndex]) {
            let category = this.state.searchCategories[categoryIndex].toLowerCase();
            let results = [];

            if (this.state.searchResultsByCategory && this.state.searchResultsByCategory[category]) {
                results = this.state.searchResultsByCategory[category];
            } else {
                results = this.state.searchResults;
            }

            this.setState({
                currentCategoryIndex: categoryIndex,
                totalCount: results.length,
                totalPages: Math.ceil(results.length / this.pageSize),
            });

            this.managePagedResults(results, 1);
        }
    }

    getPopularResults() {
        let results = [];
        this.document?.topics?.forEach(result => {
            if (result?.fields?.faqs) {
                result?.fields?.faqs?.forEach(faq => {
                    if (faq.fields?.slug?.fields) {
                        let cloneFaq = JSON.parse( customJsonstringify(faq));
                        cloneFaq.fields.url = faq.fields?.slug?.fields.slug;
                        results.push(cloneFaq);
                    }
                });
            }
        });
        return results;
    }

    getPopularResultsByTopic() {
        let popularResultsByTopic = {};
        this.document?.topics?.forEach(result => {
            popularResultsByTopic[result.fields.topic] = result.fields.faqs;
        });

        return popularResultsByTopic;
    }

    getPopularTopics() {
        let topics = ['All'];
        for (let topic in this.popularResultsByTopic) {
            topics.push(topic);
        }

        return topics;
    }

    manageResultsForPopularTopics(categoryIndex) {
        if (this.popularTopics && this.popularTopics[categoryIndex]) {
            let category = this.popularTopics[categoryIndex];
            let results = [];

            if (this.state.popularResultsByTopic && this.state.popularResultsByTopic[category]) {
                results = this.state.popularResultsByTopic[category];
            } else {
                results = this.popularResults;
            }

            this.setState({
                currentCategoryIndex: categoryIndex,
                filteredPopularResults: results,
            });
        }
    }

    handlePreviousClick() {
        this.managePagedResults(this.state.searchResults, this.state.currentPage - 1);
    }

    handleNextClick() {
        this.managePagedResults(this.state.searchResults, this.state.currentPage + 1);
    }

    handlePageNumberClick(page) {
        this.managePagedResults(this.state.searchResults, page);
    }

    handleCategoryButtonClick(buttonIndex) {
        this.manageResultsForCategory(buttonIndex);
    }

    handlePopularTopicButtonClick(buttonIndex) {
        this.manageResultsForPopularTopics(buttonIndex);
    }

    handleQAClick(qaIndex, qaResult) {
        if (this.style === SearchBlockConstants.faqStylePageLinks) {
            this.handleClickLink(qaResult);
        } else {
            this.handleClickAccordionEffect(qaIndex);
        }

    }

    handleClickLink(qaResult) {
        if (qaResult.url) {
            window.location.href = qaResult.url;
        }
    }

    handleClickAccordionEffect(qaIndex) {
        if (this.state.activeItem === qaIndex) {
            this.setState({activeItem: -1});
        } else {
            this.setState({activeItem: qaIndex});
        }
    }

    getHashValue() {
        let hash = window.location.hash;
        return hash;
    }

    getHashVariable(variable) {
        let result = '';
        let searchQueryStrings = this.getHashValue();

        if (searchQueryStrings && searchQueryStrings.length > 1) {
            var query = searchQueryStrings.substring(1);
            var vars = query.split('&');
            for (var i = 0; i < vars.length; i++) {
                var pair = vars[i].split(SearchBlockConstants.queryHashEqual);
                if (decodeURIComponent(pair[0]) === variable) {
                    result = decodeURIComponent(pair[1]);
                }
            }
        }
        return result;
    }

    dataLayerProductPageEvent(query, hasResults) {
        if (typeof (dataLayer) === 'undefined') return;

        const eventAction = hasResults ? 'event_search' : 'event_search_no_result';

        window.dataLayer.push({
            'event': 'customEvent',
            'GAeventCategory': 'event_informational_action',
            'GAeventAction': `${eventAction}`,
            'GAeventLabel': `${query}`
        });
    }

    setResultTitle() {
        this.resultsTitle = '';
        if (this.state.query !== '') {
            if (this.state.totalCount <= 0) {
                if (this.noResultLabel) {
                    this.resultsTitle = this.noResultLabel.replace('${QUERY}', this.state.query);
                }
            } else {
                let firstIndex = (this.state.currentPage - 1) * this.pageSize + 1;
                let lastIndex = this.state.currentPage * this.pageSize;

                if (lastIndex >= this.state.totalCount) {
                    lastIndex = this.state.totalCount;
                }

                if (this.resultLabel) {
                    this.resultsTitle = this.resultLabel
                        .replace('${QUERY}', '<em>' + this.state.query + '</em>')
                        .replace('${FIRST_INDEX}', firstIndex)
                        .replace('${LAST_INDEX}', lastIndex)
                        .replace('${TOTAL_RESULTS}', this.state.totalCount);
                }
            }
        }
    }

    renderCategoryName(category) {
        if(category === SearchBlockConstants.allResultsCategory) {
            return this.document?.allResultsLabel?.fields?.text || category;
        } else {
            return category;
        }
    }

    render() {
        this.setResultTitle();

        return (
            <div className="ob-faq-block">
                <div className={'ob-faq-block__page-title'}>
                    <Heading
                        tag={'h2'}>
                        {this.pageTitle}
                    </Heading>
                </div>
                <div className="ob-faq-block__search">
                    <SearchBar isSpinning={this.state.isSearching}
                               placeholder={this.searchPlaceholder}
                               defaultValue={this.state.query}
                               commonSearchesLabel={this.commonSearchesLabel}
                               onSearch={this.handleSearch.bind(this)}
                               isSearchFaqOnly={true}
                               isAutocompleteEnabled={true}
                               searchTitle={this.searchTitle}
                    />
                </div>
                {this.state.searchResults && !this.state.isSearching && this.state.searchResults.length > 0 && <>
                    <div className="ob-search-block__popular-topics">
                        <div className="ob-search-block__popular-topics-title">
                            {this.resultsBySectionLabel}
                        </div>

                        <div className="ob-search-block__popular-topics-container">
                            {this.state.searchCategories.map((category, i) => {
                                return (<div className="ob-search-block__popular-topics__toggle-wrapper" key={i}>
                                    <SearchToggleButton
                                        text={this.renderCategoryName(category)}
                                        isActive={i === this.state.currentCategoryIndex}
                                        onClick={this.handleCategoryButtonClick.bind(this, i)}
                                    />
                                </div>);
                            })}
                        </div>
                    </div>
                    <div className={'ob-search-block__results-title'}
                        dangerouslySetInnerHTML={{__html: this.resultsTitle}}/>
                    <div className="ob-faq-block__search-results">
                        {this.state.pagedResults.map((result, index) =>
                            <FAQQA key={'searchResult' + index}
                                qa={result}
                                documentKey={index}
                                activeItem={this.state.activeItem}
                                faqStyle={this.style}
                                onClickCallback={this.handleQAClick.bind(this, index, result)}
                            />
                        )
                        }
                    </div>
                    <div className="ob-search-block__paging">
                        <Paging previousLabel="Previous"
                            hasPrevious={this.state.currentPage > 1}
                            onPreviousClick={this.handlePreviousClick.bind(this)}
                            nextLabel="Next"
                            hasNext={this.state.currentPage < this.state.totalPages}
                            onNextClick={this.handleNextClick.bind(this)}
                            currentPage={this.state.currentPage}
                            totalPages={this.state.totalPages}
                            showPageNumbers={true}
                            onPageChange={this.handlePageNumberClick.bind(this)}
                        />
                    </div>
                </>}
                {(this.state.query && !this.state.isSearching && this.state.searchResults && this.state.searchResults.length <= 0) &&
                <div className={'ob-search-block__no-results-title'}>
                    {this.resultsTitle}
                </div>
                }
                {(!this.state.query && this.popularTopics) &&
                <div className='ob-faq-block__popular-topics'>
                    <div className={'ob-faq-block__popular-topics-title'}>
                        {this.popularTopicsText}
                    </div>
                    <div className={'ob-faq-block__popular-topics-container'}>

                        {this.popularTopics.map((category, index) => {
                            return (<div className='ob-faq-block__popular-topics__toggle-wrapper' key={index}>
                                <SearchToggleButton
                                    text={this.renderCategoryName(category)}
                                    isActive={index === this.state.currentCategoryIndex}
                                    onClick={this.handlePopularTopicButtonClick.bind(this, index)}
                                />
                            </div>)
                        })}
                    </div>
                    <div className="ob-faq-block__search-results">
                        {this.state.filteredPopularResults.map((result) => {
                            const index = this.popularResults.indexOf(this.popularResults.find(popularResult => popularResult.fields.name === result.fields.name));
                            return <FAQQA key={'searchResult' + index}
                                          qa={result.fields}
                                          documentKey={index}
                                          activeItem={this.state.activeItem}
                                          faqStyle={this.style}
                                          onClickCallback={this.handleQAClick.bind(this, index, result.fields)}
                            />
                        }
                        )}

                        {this.state.currentCategoryIndex === 0 &&
                            this.state.allFaqs.map((result, index) => (
                                <FAQQA key={'searchResult' + index}
                                       qa={result}
                                       documentKey={index}
                                       activeItem={this.state.activeItem}
                                       faqStyle={this.style}
                                       onClickCallback={this.handleQAClick.bind(this, index, result)}
                                />
                            ))
                        }
                    </div>
                </div>
                }
            </div>
        );
    }
}

FAQBlock.propTypes = {
    extraAttributes: PropTypes.any,
    document: PropTypes.object,
};

FAQBlock.defaultProps = {
    extraAttributes: {},
    document: {},
};
